

import { defineComponent, onMounted, reactive, toRefs, ref, watch } from "vue";
import moment from 'moment';
import { getChecklists } from "@/services/OnePageReportService";
import { useRouter } from "vue-router";
import { ContentLoader } from 'vue-content-loader';
import store from "@/store/index";

export default defineComponent({
    name: "ChecklitsCard",
    components: {
        ContentLoader
    },
    props: {
        filters: Object,
        title: String,
    },
    
    setup(props) {
        const title = ref("");
        const router = useRouter();
        let animate = ref(true);

        const data = reactive({
            faturamento: {
                totalQtdCheck: 0,
                tempoRealQtdCheck: 0,
            },
            seen: true,
        });

        let totalQtdCheckFormat = ref("");
        let tempoRealQtdCheckFormat = ref("");
        let totalQtdCheck = ref(0);
        let tempoRealQtdCheck = ref(0);


        function trataData(data){
            let filters = data;
            data = data.split("/");
            const dataInicial = moment(data[0]);
            const dataFinal = moment(data[1]);
            const mesmoAno = dataFinal.year() === dataInicial.year();
            const mesmoMes = dataFinal.month() === dataInicial.month();
            if (mesmoAno && mesmoMes) {
                title.value = `Período selecionado ${dataInicial.locale("pt-br").format('MMMM - YYYY').replaceAll("-", "de")}`;
                return filters;
            }else{
                title.value = ` Entre ${dataInicial.locale("pt-br").format('MMMM - YYYY').replaceAll("-", "de")} até ${dataFinal.locale("pt-br").format('MMMM - YYYY').replaceAll("-", "de")}`;
                return filters;
            }
        }

        function retornaFiltroData(dataInicial, dataFinal){
            dataInicial = moment(dataInicial).format();
            dataFinal = moment(dataFinal).format();
            dataInicial = dataInicial.substr(0, 10) + " 00:00:00";
            dataFinal = dataFinal.substr(0, 10) + " 23:59:59";

            const filters = `${dataInicial}/${dataFinal}`;
            return filters;         
        };
        
        let link_v3 = "../v3"; 


        watch(() => props.filters, () => {
            // 2021-04-01/2021-05-31 É um exemplo com dados
             const filters = trataData(props.filters?.data); 

            let dominio_gestao = store.getters.layoutConfig("distribuidor.dominio_gestao");
            if(dominio_gestao == "localhost:8080"){
                link_v3 = "http://localhost/acampa-gestao-pra/v3/";
            }else{
                link_v3 =  "../v3/";
            }

            getChecklistsTotais(props.filters?.codConcessionaria, props.filters?.data);
        });


        async function getChecklistsTotais(codConcessionaria, filtersData){
            animate.value = true;
            let diaAtuaInicial = moment().startOf("day").toISOString();
            let diaAtuaFinal = moment().endOf("day").toISOString();

            const dataTratada = retornaFiltroData(diaAtuaInicial, diaAtuaFinal);
            const response1 = await getChecklists(codConcessionaria, filtersData);
            const response2 = await getChecklists(codConcessionaria, dataTratada);
            
            totalQtdCheckFormat.value = "0,00";
            tempoRealQtdCheckFormat.value = "0,00";
            totalQtdCheck.value = 0;
            tempoRealQtdCheck.value = 0;
            
            totalQtdCheck.value = response1;
            tempoRealQtdCheck.value = response2;
            totalQtdCheckFormat.value = new Intl.NumberFormat('de-DE',{ maximumFractionDigits: 0, minimumFractionDigits:0 }).format(totalQtdCheck.value);
            tempoRealQtdCheckFormat.value = new Intl.NumberFormat('de-DE',{ maximumFractionDigits: 0, minimumFractionDigits:0 }).format(tempoRealQtdCheck.value);
            animate.value = false;
        }

        const codConcessionariaSelected:any = ref("");
        const optionsConcessionaria = ref([]);
        const path = "https://desenvolvimento.acampa.app"

        

        function AOficinaDigitalGerenciar() {
           router.push( path + '/historicochecklistlist.php');
        };

        
        
        return {
            link_v3,
            totalQtdCheckFormat,
            tempoRealQtdCheckFormat,
            totalQtdCheck,
            tempoRealQtdCheck,
            ...toRefs(data),
            animate,
            AOficinaDigitalGerenciar,
            codConcessionariaSelected,
            optionsConcessionaria,
            title,
            trataData,
        };
    },
});

