
import { defineComponent, onMounted, reactive, toRefs, ref, watch } from "vue";
import moment from 'moment';
import { getUsuariosApp } from "@/services/OnePageReportService";
import { getAcessosApp } from "@/services/OnePageReportService";
import ApexChart from "apexcharts";
import { ContentLoader } from 'vue-content-loader';
import store from "@/store/index";

export default defineComponent({
    name: "UsoFerramentaCard",
    components: {
        ContentLoader
    },
    props: {
        filters: Object,
        animate: Boolean,
        temChecklist: Boolean,
        title: String,
    },
    setup(props) {
        const title = ref("");
        
        const data = reactive({
            usoFerramenta: {
                totalUsuarios: 0,
                totalUsuariosGlobais: 0,
                totalUsuariosConsultores: 0,
                totalUsuariosMecanicos: 0,
                totalAcessos: 0,
            },
        });

        let totalUsuarios= ref(0);
        let totalUsuariosGlobais= ref(0);
        let totalUsuariosConsultores= ref(0);
        let totalUsuariosMecanicos= ref(0);
        let totalAcessos= ref(0);
        
        let animate = ref(true);
        
        onMounted(() =>{
        
        })

        function trataData(data){
            let filters = data;
            data = data.split("/");
            const dataInicial = moment(data[0]);
            const dataFinal = moment(data[1]);
            const mesmoAno = dataFinal.year() === dataInicial.year();
            const mesmoMes = dataFinal.month() === dataInicial.month();
            if (mesmoAno && mesmoMes) {
                title.value = `Período selecionado ${dataInicial.locale("pt-br").format('MMMM - YYYY').replaceAll("-", "de")}`;
                return filters;
            }else{
                title.value = ` Entre ${dataInicial.locale("pt-br").format('MMMM - YYYY').replaceAll("-", "de")} até ${dataFinal.locale("pt-br").format('MMMM - YYYY').replaceAll("-", "de")}`;
                return filters;
            }
        }

        function retornaFiltroData(dataInicial, dataFinal){
            dataInicial = moment(dataInicial).format();
            dataFinal = moment(dataFinal).format();
            dataInicial = dataInicial.substr(0, 10) + " 00:00:00";
            dataFinal = dataFinal.substr(0, 10) + " 23:59:59";

            const filters = `${dataInicial}/${dataFinal}`;

            return filters;
        };

        let link_v2 = ref("");
        watch(() => props.filters, () => {
            // 2021-04-01/2021-05-31 É um exemplo com dados
             const filters = trataData(props.filters?.data); 

            let dominio_gestao = store.getters.layoutConfig("distribuidor.dominio_gestao");
            if(dominio_gestao == "localhost:8080"){
                link_v2.value = "http://localhost/acampa-gestao-pra/v2/";
            }else{
                link_v2.value = "../v2/";
            }

            getUsoFerramenta(props.filters?.codConcessionaria, props.filters?.data);
        });


        async function getUsoFerramenta(codConcessionaria, filtersData){
            
            animate.value = true;
            let diaAtuaInicial = moment().startOf("day").toISOString();
            let diaAtuaFinal = moment().endOf("day").toISOString();

            const qtdUsuarios = await getUsuariosApp(codConcessionaria, filtersData);
            const qtdAcessos = await getAcessosApp(codConcessionaria, filtersData);
            
            totalUsuarios.value = 0;
            totalUsuariosGlobais.value = 0;
            totalUsuariosConsultores.value = 0;
            totalUsuariosMecanicos.value = 0;
            totalAcessos.value = 0;

            totalUsuariosGlobais.value = qtdUsuarios.global;
            totalUsuariosConsultores.value = qtdUsuarios.consultor;
            totalUsuariosMecanicos.value = qtdUsuarios.mecanico;
            totalAcessos.value = qtdAcessos.qtdAcessos;
            totalUsuarios.value =  totalUsuariosGlobais.value+totalUsuariosConsultores.value+totalUsuariosMecanicos.value;

            // totalAcessos.value = qtdAcessos.qtdAcessos;
            // tempoRealOrcamento.value = orcamentoReal.qtdOrcamentos;
            // faturamentoTotalOrcamento.value = orcamentoFaturamentoTotal.faturamentoTotal;
     
            animate.value = false;
        }

        return {
            link_v2,
            totalUsuarios,
            totalUsuariosGlobais,
            totalUsuariosConsultores,
            totalUsuariosMecanicos,
            totalAcessos,
            ...toRefs(data),
            animate,
            title,
            trataData
        };
    },
});

